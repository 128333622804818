
.slick-prev:before,
.slick-next:before {
  color: #E05128 !important;
}

.legend{
    font-family: 'Montserrat';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    /* line-height: 22px; */
    /* or 138% */
    
    /* text-align: center; */
    /* max-width: 500px; */
    color: #000000;
}