
.page-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 24px;
    text-align: center;

    /* Black */

    color: #000000;

    margin-top: 5vh;
    margin-bottom: 5vh;
}

.page-container{
    background-color: #EEE3C1;
    margin-bottom: 50px;
}

.page-height{
    min-height: 82vh;
    padding-top: 10px;
}

/************** *****/


.legend-link{
    margin-top: 20px;
}

.page-body{
    background-color: #EEE3C1;
}

.entrevista-mainBody {
    background-color: #EEE3C1;
    min-height: 82vh;
    text-align: start;
    overflow-wrap: break-word;
}

.entrevista-titulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    padding-top: 20px;
}

.entrevista-texto{
    padding-top: 20px;
    text-indent: 10px;
    text-align: justify;
}
.entrevista-texto > p{
    padding-left: 0px !important;
}
.green-box {
    padding: 33px 46px 33px 26px !important;
    background-color: rgba(112, 123, 57, 0.3);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    
    text-align: justify;

    text-indent: 0px;
    margin-bottom: 20px;
}

.entrevistas-carousel-row {
    padding-bottom: 40px;
    padding-top: 20px;
}

.modal-link{
    all: revert;
    color: #E05128;
}

.modal-link:hover{
    cursor: pointer;
}


.entrevista-sub-text{
    text-align: end;
    font-size: 12px;
}