


.namidia-mainContainer{
    min-height: 70vh;

    background-color: #EEE3C1;
}

.namidia-textoContainer{
    height: 100%;
    /* width: 50%; */
    padding-top: 80px !important;
    text-align: start;
}

.namidia-text-namidia{
    font-family: 'BlippoBlack';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    
    color: #E05128;
}


.namidia-text-mundo{
    font-family: 'BernardMTStd-Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}


.namidia-imageContainer{
    height: 100%;
    margin-right: 120px;
    margin-top: 99px;
    position: relative;
}

.namidia-imageContainer img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 600px;
    object-fit: contain !important;
    position: absolute;
}

/*** Image ***/

.image-mundo {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-mundo:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

/*** No Mundo ***/

.namidia-text-caminho{
    font-family: 'BernardMTStd-Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}

.image-caminho {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-caminho:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-entrevistas{
    font-family: 'Orlando Caps';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}

.image-entrevistas {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-entrevistas:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

/*** */

.namidia-text-deslocamento{
    font-family: 'Yuumy Foodies';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}

@media(min-width: 600px){
    .namidia-text-deslocamento{
        position: fixed;
        top: 300px;
        left: 600px;
    }
}

.image-deslocamento {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-deslocamento:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-retratos{
    font-family: 'Dear Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-retratos{
        position: fixed;
        top: 600px;
        left: 350px;
    }
}


.image-retratos {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-retratos:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-love{
    font-family: 'Black Arcade Script';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-love{
        position: fixed;
        top: 400px;
        left: 400px;
    }
}

.image-love {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-love:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-vozes{
    font-family: 'Bradley Hand';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}

@media(min-width: 600px){
    .namidia-text-vozes{
        position: fixed;
        top: 500px;
        left: 500px;
    }
}

.image-vozes {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-vozes:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */


