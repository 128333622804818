


.namidia-mainContainer{
    min-height: 70vh;

    background-color: #EEE3C1;
}

.namidia-textoContainer{
    height: 100%;
    /* width: 50%; */
    padding-top: 80px !important;
    text-align: start;
}

.namidia-text-namidia{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    
    color: #E05128;
}


.namidia-text-mundo{
    font-family: 'BernardMTStd-Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-mundo{
        position: fixed;
        top: 220px;
        left: 600px;
    }
}

.namidia-text-verao{
    font-family: 'CopperplateBold';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    
    color: #000000;

    margin-top: 48px !important;
}
@media(min-width: 600px){
    .namidia-text-verao{
        position: fixed;
        top: 280px;
        left: 320px;
    }
}

.namidia-text-vento{
    font-family: 'avenir-next-lt-pro-demi-condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    
    color: #000000;

    margin-top: 48px !important;
}

@media(min-width: 600px){
    .namidia-text-vento{
        position: fixed;
        top: 360px;
        left: 420px;
    }
}

.namidia-text-glossario{
    font-family: 'ludovicowoodcut';
    font-weight: 400;
    font-size: 38px;
    line-height: 32px;
    
    color: #000000;

    margin-top: 48px !important;
    width: 540px;
    overflow-wrap: break-word;
    max-width: 100vw;
}

@media(min-width: 600px){
    .namidia-text-glossario{
        position: fixed;
        top: 460px;
        left: 300px;
    }
}

.namidia-text-sala{
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;


    margin-top: 48px !important;
    width: 540px;
}
@media(min-width: 600px){
    .namidia-text-sala{
        position: fixed;
        top: 560px;
        left: 480px;
    }
}

.namidia-imageContainer{
    height: 100%;
    margin-right: 120px;
    margin-top: 99px;
    position: relative;
}

.namidia-imageContainer img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 600px;
    object-fit: contain !important;
    position: absolute;
}

/*** Image ***/

.image-mundo {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.image-verao {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.image-vento {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.image-glossario {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.image-sala {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-mundo:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

.namidia-text-verao:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}


.namidia-text-vento:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

.namidia-text-glossario:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

.namidia-text-sala:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
