.carouselContainer{
    width: 100%;
    /* padding: 0 200px; */
    margin: 8px 8px 7px 0;
}

.cursorHand{
    cursor: pointer;
}

.bodyContainer{
    background-color: #EEE3C1;
    /* height: 100vh; */
}

.carouselImageContainer {
    height: 70vh;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 100%;
    object-fit: scale-down;
}

.carousel .slide .legend {
    border-radius: 0px;
    width: 100%;
    left: 45%;
    bottom: 0;
    height: 107px;
    padding-left: 44px;
    margin-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: start; */

    opacity: 1;
    background: rgba(0, 0, 0, 0.75);

    display: none;
}

.carousel .thumbs-wrapper {
    margin-top: 10px;
}

.carouselTextPrimary{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.carouselTextSecondary{
    margin-top: 0;
    
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}