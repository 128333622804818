
.mundo-container{
    min-height: 70vh;
    display: flex;
    flex-direction: column;

    background-color: #EEE3C1;

    padding-left: 10vw;
    padding-top: 61px;
    padding-right: 12vw;
    padding-bottom: 80px;
}

.mundo-text-namidia{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    color: #E05128;
}

.mundo-text-mundo{
    font-family: 'Montaga';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    
    color: #000000;
    text-align: start;
    margin-top: 8px;
}

.mundo-text-text1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    margin-top: 40px;
    text-align: justify;
}

.mundo-text-text2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    margin-top: 8px;
}

.mundo-text-text3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: start;
    margin-top: 8px;
    margin-bottom: 50px;
}


.carouselContainer{
    width: 100%;
    /* padding: 0 200px; */
    margin: 8px 8px 7px 0;
}

.carouselImageContainer {
    height: 70vh;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 100%;
    object-fit: scale-down;
}


.carousel .slide .legend {
    border-radius: 0px;
    width: 100%;
    left: 45%;
    bottom: 0;
    height: 107px;
    padding-left: 44px;
    margin-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: start; */

    opacity: 1;
    background: rgba(0, 0, 0, 0.75);

    /* display: none; */
}

.carousel .thumbs-wrapper {
    margin-top: 10px;
}

.carouselTextPrimary{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.carouselTextSecondary{
    margin-top: 0;
    
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
}


.slick-prev:before,
.slick-next:before {
  color: #E05128 !important;
}

.legend{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #000000;
}