


.namidia-mainContainer{
    min-height: 70vh;

    background-color: #EEE3C1;
}

.namidia-textoContainer{
    height: 100%;
    /* width: 50%; */
    padding-top: 80px !important;
    text-align: start;
}

.namidia-text-namidia{
    font-family: 'BlippoBlack';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
    
    color: #E05128;
}


.namidia-text-mundo{
    font-family: 'Montaga';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}


.namidia-imageContainer{
    height: 100%;
    margin-right: 120px;
    margin-top: 99px;
    position: relative;
}

.namidia-imageContainer img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 600px;
    object-fit: contain !important;
    position: absolute;
}

/*** Image ***/

.image-mundo {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-mundo:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}

/*** No Mundo ***/

.namidia-text-caminho{
    font-family: 'Montaga';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}

@media(min-width: 600px){
    .namidia-text-caminho{
        position: fixed;
        top: 220px;
        left: 600px;
    }
}

.image-caminho {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-caminho:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-mar{
    font-family: 'Belinda';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-mar{
        position: fixed;
        top: 300px;
        left: 300px;
    }
}

.image-mar {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-mar:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-jardim{
    font-family: 'CopperplateBold';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-jardim{
        position: fixed;
        top: 370px;
        left: 400px;
    }
}


.image-jardim {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-jardim:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-branco{
    font-family: 'CooperHewitt-Thin';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-branco{
        position: fixed;
        top: 440px;
        left: 600px;
    }
}


.image-branco {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-branco:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-ecos{
    font-family: 'EccentricStd';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-ecos{
        position: fixed;
        top: 490px;
        left: 300px;
    }
}

.image-ecos {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-ecos:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */

.namidia-text-paris{
    font-family: 'P22 Operina Romano';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;

    color: #000000;

    margin-top: 49px !important;
}
@media(min-width: 600px){
    .namidia-text-paris{
        position: fixed;
        top: 580px;
        left: 600px;
    }
}

.image-paris {
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
}

.namidia-text-paris:hover {
    color:#1F6F9B; 
    text-decoration:none; 
    cursor:pointer; 
}
/*** */
