
.logoHeader{
    padding-right: 7%;
    display: flex;
    justify-content: right;
    align-items: center;

    font-family: Urbanist;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.07em;
    text-align: left;
    
    color: #EEE3C1 !important;
}

.mainHeader{
    height: 80px;
    background-color: #707B39;
    color: #EEE3C1 !important;
    justify-content: center;
    z-index: 100;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
}

.color-bege a{
    color: #EEE3C1 !important;
}

.color-bege div{
    color: black !important;
    
    background-color: #E05128;
}

.dropdown-menu a:hover{
    color: black !important;
    
    background-color: #E05128;
}
.dropdown-menu a{
    color: black !important;
}


.navbar-expand .navbar-nav .dropdown-menu{
    border-radius: 0px;
}

.link-default {
    /* color: var(--bs-link-color); */
    color: black;
    text-decoration: underline;
}

.link-default:hover{
    cursor: pointer;
}

.page-background{
    background-color: #EEE3C1;
}