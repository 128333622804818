

.bio-container{
    min-height: 80vh;
    display: flex;
    flex-direction: column;

    background-color: #EEE3C1;

    text-align: start;
    overflow-wrap: break-word;
}


.bio-titulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    
    color: #000000;
    padding-top: 20px;
}

.bio-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    
    color: #000000;

    padding-top: 14px;
    padding-bottom: 20px;
}


.bio-lattes a{
    all: revert;
}
.bio-lattes{
    text-align: right;
}
