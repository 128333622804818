

.page-body{
    background-color: #EEE3C1;
}

.container-mainBody {
    background-color: #EEE3C1;
    min-height: 80vh;
    text-align: start;
    overflow-wrap: break-word;

    padding-top: 60px;
}

.namidia-text-maintitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    color: #E05128;
}

.namidia-text-subtitle{
    font-family: 'Montaga';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    
    color: #000000;
    text-align: start;
    margin-top: 8px !important;
}

.namidia-maintext{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    margin-top: 30px !important;
}

.namidia-subtext{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: start;
    margin-top: 8px;
    margin-bottom: 50px;
}
.namidia-subtext > div {
    margin-bottom: 5px;
}

/* carousel */
.namidia-carousel-wrapper{
    padding-bottom: 50px;
    min-height: 400px;
}
