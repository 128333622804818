.footerBody{
    position: static;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 100%;

    text-align: center;


    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height */

    background-color: #EEE3C1;
    color: #000000;

}

.footerlink{
    padding-left: 5px;
    color: inherit; /* blue colors for links too */
}

  