

.contato-body {
    /* background-image: url("../../../../public/assets/shared/contato.png");
    background-color: #cccccc;

    background-size:     fill;                      
    background-repeat:   repeat;
    background-position: center center;    */
    
}
.image-contato {
    background-image: url('../../../../public/assets/shared/contato.png');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100vh;
    width: 100%;
}
.orange-box{
    margin-top: 200px;
    height: 330px;
    background-color: #E05128;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.contato-text{
    text-align: left;
    height: 100%;
}
.contato-col {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-image-contato {
    /* width: 170px; */
    /* height: 170px; */
}

.contato-col img{
    /* width: 170px;
    height: 170px; */
    object-fit: scale-down !important;
}

.susana-name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #FFFFFF;

}
.susana-info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height */

    color: #FFFFFF;
    padding-top: 7px;
}

.contato-icon{
    width: 20px !important;
    padding-left: 0px !important;
    padding-right: 4px !important;
}