
@font-face {
  font-family: "Dear Sans";
  src: url('../public/assets/fonts/DearSans.otf') format('truetype');
}

@font-face {
  font-family: "Orlando Caps";
  src: url('../public/assets/fonts/Orlando Caps CG.otf') format('truetype');
}

@font-face {
  font-family: "Yuumy Foodies";
  src: url('../public/assets/fonts/YummyFoodies-demo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Black Arcade Script";
  src: url('../public/assets/fonts/Black Arcade Script.ttf') format('truetype');
}

@font-face {
  font-family: "Bradley Hand";
  src: url('../public/assets/fonts/Bradley Hand ITC TT Bold.ttf') format('truetype');
}

@font-face {
  font-family: "BlippoBlack";
  src: url('../public/assets/fonts/BlippoBlack.ttf') format('truetype');
}

@font-face {
  font-family: "BernardMTStd-Condensed";
  src: url('../public/assets/fonts/BernardMTStd-Condensed.otf') format('truetype');
}

@font-face {
  font-family: "Belinda";
  src: url('../public/assets/fonts/belinda.otf') format('truetype');
}

@font-face {
  font-family: "CopperplateBold";
  src: url('../public/assets/fonts/CopperplateBold.ttf') format('truetype');
}

@font-face {
  font-family: "CooperHewitt-Thin";
  src: url('../public/assets/fonts/CooperHewitt-Thin.ttf') format('truetype');
}

@font-face {
  font-family: "EccentricStd";
  src: url('../public/assets/fonts/EccentricStd.otf') format('truetype');
}

@font-face {
  font-family: "P22 Operina Romano";
  src: url('../public/assets/fonts/P22 Operina Romano.ttf') format('truetype');
}

@font-face {
  font-family: "avenir-next-lt-pro-demi-condensed";
  src: url('../public/assets/fonts/avenir-next-lt-pro-demi-condensed.otf') format('truetype');
}

@font-face {
  font-family: "ludovicowoodcut";
  src: url('../public/assets/fonts/Ludovico W03 Woodcut.ttf') format('truetype');
}

.App {
  text-align: center;
}

html { background-color: #EEE3C1; } 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
