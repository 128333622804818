
.page-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    /* Black */

    color: #000000;

    margin-top: 5vh;
    margin-bottom: 5vh;
}

.page-background{
    background-color: #EEE3C1;
}

.artigos-container{
    background-color: #EEE3C1;
    margin-bottom: 30px;
}

.page-height{
    min-height: 82vh;
    padding-top: 10px;
}

.line-orange{
    height: 1px;
    background-color: #E05128;
    margin-bottom: 20px;
}